[data-v-ebff6e34] .el-textarea__inner {
  height: 70vh;
}
[data-v-ebff6e34] .el-form-item__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.item[data-v-ebff6e34] {
  font-size: 24px;
  margin-left: 6px;
}
.iconLine[data-v-ebff6e34] {
  font-size: 24px;
  margin-left: 6px;
  padding: 4px 0;
}
.borderLine[data-v-ebff6e34] {
  width: 100%;
  border-top: 1px solid #DCDFE6;
  padding-top: 12px;
}